<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
      strModel="Cadastro/TabelaPreco"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :loading="loading"
      :filter="formFiltros"
      @register="onRegister"
      @edit="onEdit"
      @search="getTabelaPreco"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getTabelaPreco();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          collapse: 20,
          value: "strNome",
        },
        {
          text: "Unidade de Trabalho",
          sortable: true,
          collapse: 10,
          collapseLabel: "Visualizar...",
          value: "strUnidadeTrabalho",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{
        intTipoCadastroGeralId: 300
      }
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "cadastro.preco.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "cadastro.preco.edit",
        params: { intId: data.intId },
      });
    },

    getTabelaPreco() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get(
          "Cadastro/TabelaPreco",
          this.formFiltros
        )
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objTabelaPreco) => {
              let strUnidadeTrabalho = "--";

              if (objTabelaPreco.empresas.length) {
                strUnidadeTrabalho = '<table class="table">'
                strUnidadeTrabalho += '<tr><th>Empresa</th><th>Unidade</th></tr>'

                objTabelaPreco.empresas.forEach((objTabelaPrecoEmpresa) => {
                  strUnidadeTrabalho += '<tr>'
                    strUnidadeTrabalho += '<td>'+objTabelaPrecoEmpresa.empresa.strNome+'</td>'
                    strUnidadeTrabalho += '<td>'+objTabelaPrecoEmpresa.unidade.strNome+'</td>'
                  strUnidadeTrabalho += '</tr>'
                });

                strUnidadeTrabalho += '</table>'
              }

              let item = {
                intId: objTabelaPreco.intId,
                strCnpj: objTabelaPreco.strCnpj,
                strNome: objTabelaPreco.strNome,
                strUnidadeTrabalho: strUnidadeTrabalho,
                _item: objTabelaPreco,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
